import React from 'react'
import { Link } from 'react-router-dom'

const Admin = () => {
  return (
    <div>
      <Link to="/posts">
        Blog
      </Link>
    </div>
  )
}

export default Admin
