import React, { useEffect, useState } from 'react'
import { store } from '../../firebase'

import Card from './Card'

const Forestando = () => {

  const [post, setPost] = useState('')

  const [imageSlider, setImageSlider] = useState([])
  const [textPost, setTextPost] = useState('')

  useEffect(() => {
    const getPost = async () => {
      const { docs } = await store.collection('new').get()
      const newArray = docs.map(item => ({ id: item.id, ...item.data() }))
      const roxanneArray = newArray.filter(arr => arr.category === 'forestando')
      setPost(roxanneArray)
    }
    getPost()
  }, [])


  // const imagesSlider = (post) => {
    
  //   setImageSlider(post)

  // }

  return (
    <div>
      {
        post.length !== 0 ? 
        (post.map(item => (
          <div className="post__card">
            <Card 
              images={item.imageLinks} 
              title={item.title}
              text={item.description} 
              category={item.category}
            />
          </div>
        ))) : (<div>loading Forestando</div>)
      }
    </div>
  )
}

export default Forestando 