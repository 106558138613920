import React, { Component } from 'react'
import HomeRoxanne from '../components/home/HomeRoxanne'
import HomeForestando from '../components/home/HomeForestando'

export default class Store extends Component {
  render() {
    return (
      <div class="home">
        {/* <HomeRoxanne/>
        <HomeForestando/> */}
        <div class="home__header">
          <h1>cristian dussán</h1>
          <h2>I have worked for more than 4 years as a software designer and developer for clients in different parts of the world, creating complete solutions following modern design and development trends.</h2>
        </div>

        {/* sltwtr */}
        <div class="home__content">
          <div class="home__projects">
            <h3 class="home__projects--company">sltwtr</h3>
            <div class="line"></div>
            <p class="home__projects--description">developer and designer for creative agency from California</p>
            <div class="line"></div>
            <div class="remote">
              <p class="home__projects--ubication">remote</p>
            </div>
            <div class="line"></div>
            <p class="home__projects--time">2022 - 2023</p>
          </div>

          <div class="home__cards">
            <a target='_blank' href='https://www.motherdenim.com/'>
              <div class="mother">
                <h3>mother</h3>
              </div>
            </a>
            <a target='_blank' href='https://caddislife.com/'>
              <div class="caddis">
                <h3>caddis</h3>
              </div>
            </a>
            <a target='_blank' href='https://www.volcom.es/'>
              <div class="volcom">
                <h3>volcom</h3>
              </div>
            </a>
          </div>
        </div>
        {/* gradiweb */}
        <div class="home__content">
          <div class="home__projects">
            <h3 class="home__projects--company">gradiweb</h3>
            <div class="line"></div>
            <p class="home__projects--description">developer for creative agency from France</p>
            <div class="line"></div>
            <div class="remote">
              <p class="home__projects--ubication">remote</p>
            </div>
            <div class="line"></div>
            <p class="home__projects--time">2021 - 2022</p>
          </div>

          <div class="home__cards">

            <a href="https://mybleen.com/" target='_blank'>
              <div class="bleen">
                <h3>bleen</h3>
              </div>
            </a>
            <a href="https://eovolt.com/" target='_blank'>
              <div class="eovolt">
                <h3>eovolt</h3>
              </div>
            </a>
            <a href="https://es.luneale.co/en" target='_blank'>
              <div class="luneale">
                <h3>luneale</h3>
              </div>
            </a>
          </div>
        </div>

        {/* unusual minds */}
        <div class="home__content">
          <div class="home__projects">
            <h3 class="home__projects--company">unusual minds</h3>
            <div class="line"></div>
            <p class="home__projects--description">developer for a design company from Bogotá</p>
            <div class="line"></div>
            <div class="remote">
              <p class="home__projects--ubication">bogotá</p>
            </div>
            <div class="line"></div>
            <p class="home__projects--time">2019 - 2020</p>
          </div>

          <div class="home__cards">
            <a href="https://www.artify.co/" target='_blank'>
              <div class="artify">
                <h3>artify</h3>
              </div>
            </a>
            <a href="https://www.bypeople.com/" target='_blank'>
              <div class="bypeople">
                <h3>bypeople</h3>
              </div>
            </a>
            <a href="https://www.iconshock.com/" target='_blank'>
              <div class="iconshock">
                <h3>iconshock</h3>
              </div>
            </a>
          </div>
        </div>
        {/* world speakers */}
        {/* <div class="home__content">
          <div class="home__projects">
            <h3>world speakers</h3>
            <div class="line"></div>
            <p>designer and film maker for events company </p>
            <div class="line"></div>
            <div class="remote">
              <p>Medellín</p>
            </div>
            <div class="line"></div>
            <p>2019</p>
          </div>

          <div class="home__cards">
            <div class="mother">
              <h3>mother</h3>
            </div>
            <div class="caddis">
              <h3>caddis</h3>
            </div>
            <div class="volcom">
              <h3>volcom</h3>
            </div>
          </div>
        </div> */}

        {/* sltwtr */}
        <div class="home__content">
          <div class="home__projects home__projects--personal">
            <h3 class="home__projects--company">product designer</h3>
            <div class="line"></div>
            <p class="space"></p>
            <div class="line"></div>
            <p class="home__projects--time">personal projects</p>
          </div>

          <div class="home__cards">
            <div class="roxanne">
              <h3>roxanne</h3>
            </div>
            <div class="piedemonte">
              <h3>piedemonte</h3>
            </div>
            <div class="visionchamanica">
              <h3>vision chamánica</h3>
            </div>
          </div>
        </div>

        {/* tags */}
        <div class="home__tags">
          <div class="home__tags--item"><p>html</p></div>
          <div class="home__tags--item"><p>javascript</p></div>
          <div class="home__tags--item"><p>css</p></div>
          <div class="home__tags--item"><p>scss</p></div>
          <div class="home__tags--item"><p>git</p></div>
          <div class="home__tags--item"><p>shopify</p></div>
          <div class="home__tags--item"><p>liquid</p></div>
          <div class="home__tags--item"><p>react</p></div>
          <div class="home__tags--item"><p>figma</p></div>
          <div class="home__tags--item"><p>ui - ux</p></div>
        </div>
      </div>
    )
  }
}